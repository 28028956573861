<template>
    <div class="participant-card" v-if="partner.id">
        <div class="participant-card__partner-detail">
            <ProfileAvatar class="participant-card__avatar" :image="partner.avatarUrl" />
            <div class="participant-card__partner-detail-text">
                <component
                    v-if="partnerDisplayName"
                    :to="profilePath"
                    ref="participantName"
                    :is="nameElement"
                    :class="nameClass"
                >
                    {{ partnerDisplayName }}
                </component>
                <p class="participant-card__partner-title">{{ partnerTitle }}</p>
                <p class="participant-card__partner-group">{{ partnerGroup }}</p>
                <div v-if="isDEAdmin">
                    <p v-if="participantType === EMPLOYEE_ROLE">
                        {{ $store.state.page.employee.data.email }}
                    </p>
                    <p v-if="participantType === EDUCATOR_ROLE">
                        {{ this.request.educatorId }}
                    </p>
                </div>
            </div>
        </div>
        <slot>
            <NebulaButton
                v-if="showMessage"
                class="participant-card__message-button"
                text="Message"
                type="ghost"
                size="l"
                iconLeft="mail"
                routerLink
                :link="messageLink"
            />
        </slot>
    </div>
</template>

<script>
import { NebulaButton } from '@discoveryedu/nebula-components';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import {
    roles,
    MEMBER_ROLES,
    scheduled,
} from '@/constants';
import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'ParticipantCard',
    components: {
        NebulaButton,
        ProfileAvatar,
    },
    mixins: [createPayload],
    props: {
        // this component can pull the needed data from a request,
        // OR a participant can be passed in directly to the participant prop
        request: Object,
        participant: Object,
        pendingParticipant: Object,
        participantType: {
            type: String,
            default: 'employee',
        },
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
        isConnector() {
            return this.appMode === roles.EMPLOYEE_ROLE;
        },
        showMessage() {
            const isScheduled = this.request && this.request.status === scheduled;
            // if not in use on a request, there would be no associated message thread
            if (!this.request || !isScheduled || !MEMBER_ROLES.includes(this.appMode)) {
                return false;
            }
            return this.messagingFF;
        },
        messageLink() {
            return `/message?id=${this.request.requestId}`;
        },
        isDEAdmin() {
            return this.appMode === roles.DE_ROLE;
        },
        partner() {
            // if a request is passed in, pull the data directly from that
            if (this.request) {
                const roleSubstring = this.participantType;
                if (this.pendingParticipant) {
                    const {
                        employeeId: id,
                        firstName,
                        lastName,
                        preferredName,
                        avatarUrl,
                    } = this.pendingParticipant;

                    return {
                        id, firstName, lastName, preferredName, avatarUrl,
                    };
                }

                return {
                    id: this.request[`${roleSubstring}Id`],
                    firstName: this.request[`${roleSubstring}FirstName`],
                    lastName: this.request[`${roleSubstring}LastName`],
                    preferredName: this.request[`${roleSubstring}PreferredName`],
                    avatarUrl: this.request[`${roleSubstring}AvatarUrl`],
                };
            }

            // otherwise default to the participant data passed in
            return this.participant;
        },
        partnerDisplayName() {
            if (!this.partner.lastName) {
                return null;
            }
            return `${this.partner.preferredName || this.partner.firstName} ${this.partner.lastName}`;
        },
        avatarUrl() {
            if (this.isConnector) {
                return this.request.educatorAvatarUrl;
            }

            return this.request.employeeAvatarUrl;
        },
        viewEmployee() {
            return this.$store.getters.viewEmployee || {};
        },
        messagingFF() {
            return this.$store.getters.messagingFF;
        },
    },
    data() {
        return {
            partnerTitle: '',
            partnerGroup: '',
            nameElement: 'h4',
            profilePath: '',
            nameClass: 'participant-card__partner-name',
            EMPLOYEE_ROLE: roles.EMPLOYEE_ROLE,
            EDUCATOR_ROLE: roles.EDUCATOR_ROLE,
        };
    },
    async mounted() {
        // POPULATE PARTICIPANT TITLE AND ORGANIZATION/SCHOOL
        if (this.participantType === roles.EDUCATOR_ROLE) {
            // TODO: fetch site/location once permission is enabled, or school name added to request
            // const educatorPayload = await this.createPayload({ educatorId, view: true });
            // await this.$store.dispatch('getEducator', educatorPayload);
            this.partnerTitle = 'Educator';

            this.partnerGroup = this.request.schoolName;
        } else if (this.participant && this.participant.title) {
            // if a specific participant's data has been passed in, containing the title, use that
            this.partnerTitle = this.participant.title;
            const [org] = this.participant.organizations;
            this.partnerGroup = org.name;
        } else if (this.participantType === roles.EMPLOYEE_ROLE && this.request) {
            // if displaying an employee, the organization name and employee's title will need to be fetched from the database
            let { employeeId } = this.request;

            if (this.pendingParticipant) {
                employeeId = this.pendingParticipant.employeeId;
            }

            const payload = await this.createPayload({ employeeId });
            await this.$store.dispatch('viewEmployee', payload);
            this.nameElement = 'router-link';
            this.profilePath = `/connector/${this.$store.getters.viewEmployee.employeeId}`;
            this.partnerTitle = this.$store.getters.viewEmployee.title;
            this.nameClass = this.nameClass.concat(' participant-card__profile-link');
            const [org] = this.$store.getters.viewEmployee.organizations;
            this.partnerGroup = org.name;
        }
    },
};
</script>

<style lang='stylus'>
.participant-card {
    flat-card-internal();
    display: flex;
    flex-direction: column;
    gap: $nebula-space-2x;
    max-width: 420px;
    padding: $nebula-space-3x;
    width: 100%;

    &__message-button {
        width: 100%;
        justify-content: center;
    }

    &__partner-name, &__partner-title, &__partner-group {
        margin: 0;
    }

    &__partner-name {
        font-weight: 600;
    }

    &__partner-title, &__partner-group {
        font-size: $nebula-font-size-caption;
    }

    &__partner-group {
        color: $nebula-color-platform-neutral-700;
    }

    &__partner-detail {
        display: flex;
        gap: $nebula-space-2x;
    }
    &__profile-link {
        link();
    }
}
</style>
