<template>
    <div class="detail-block" :class="{'detail-block--simple': simple, 'detail-block--vertical': vertical}">
        <div class="detail-block__icon-label"
            v-for="(label, idx) in labels"
            :key="idx"
        >
            <NebulaIcon
                size="s"
                :symbolId="label.icon || iconMap[label.name]"
                class="detail-block__icon"
            />
            <span v-if="label.display" class="detail-block__label">
                {{ $t(label.display) }}
            </span>
        </div>
    </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import getGradeDisplay from '@/utils/getGradeDisplay';

import formatDate from '@/mixins/formatDate';
import formatTime from '@/utils/formatTime';
import subjectIconMap from '@/data/subjectIconMap';
import { tagOptions } from '@/data/formData';

export default {
    name: 'DetailBlock',
    components: {
        NebulaIcon,
    },
    data() {
        return {
            iconMap: {
                date: 'calendar',
                grade: 'learning',
                time: 'clock',
                location: 'pin',
                students: 'student',
            },
            subjectIconMap,
        };
    },
    mixins: [formatDate],
    props: {
        data: {
            type: Object,
            required: true,
        },
        simple: Boolean,
        items: Array,
        vertical: Boolean,
    },
    computed: {
        displayTime() {
            return this.formatDate(this.data.startDateTime, 'weekday');
        },
        labels() {
            // format subject as a json key
            // const subjectAsKey = this.data.subject.replace(/[- ]/g, '').trim().toLowerCase();
            const { primarySubject, gradeLevel } = this.data;
            let items = this.items || ['date', 'time', 'subject', 'grade', 'location', 'students'];

            // if start time somehow ended up after end time, update to default 30 mins
            let adjustedEndTime = this.data.endDateTime;

            if (this.data.startDateTime > adjustedEndTime) {
                adjustedEndTime = this.data.startDateTime + 1800;
            }

            const endTime = new Date(adjustedEndTime * 1000);

            if (!this.items && this.simple) {
                items = ['date', 'time'];
            }

            const labels = {
                date: {
                    display: this.displayTime,
                },
                time: {
                    display: `${formatTime(this.data.startDateTime * 1000, null, null, false)}–${formatTime(endTime)}`,
                },
                subject: {
                    display: this.subjectDisplay(),
                    icon: this.subjectIconMap[primarySubject],
                },
                grade: {
                    display: gradeLevel ? getGradeDisplay(gradeLevel) : null,
                },
                location: {
                    display: this.data.location,
                },
                students: {
                    display: `${this.data.classSize} students`,
                },
            };

            return Object.keys(labels).filter((key) => items.includes(key) && labels[key].display).map((key) => ({
                ...labels[key],
                ...{
                    name: key,
                },
            }));
            // let labelMap = [
            //     {
            //         name: 'time',
            //         display: this.displayTime,
            //     },
            //     {
            //         name: 'timerange',
            //         display: `${formatTime(this.data.startDateTime * 1000)}–${formatTime(endTime)}`,
            //     },
            // ];

            // const extended = [
            //     {
            //         name: 'subject',
            //         display: this.subjectDisplay(),
            //         icon: this.subjectIconMap[primarySubject],
            //     },
            //     {
            //         name: 'location',
            //         display: this.data.location,
            //     },
            //     {
            //         name: 'students',
            //         display: `${this.data.classSize} students`,
            //     },
            // ];

            // if (!this.simple) {
            //     labelMap = labelMap.concat(extended);
            // }

            // if (gradeLevel && !this.simple) {
            //     const subjectIdx = labelMap.findIndex((each) => each.name === 'subject');
            //     const data = {
            //         name: 'grade',
            //         display: `${getOrdinalSuffix(gradeLevel)} Grade`,
            //     };
            //     labelMap.splice(subjectIdx, 0, data);
            // }

            // return labelMap.filter((each) => Boolean(each.display));
        },
    },
    methods: {
        subjectDisplay() {
            if (!this.data.primarySubject) {
                return '';
            }
            const typeObj = tagOptions().find((each) => each.value === this.data.primarySubject);
            if (!typeObj) {
                return '';
            }
            return typeObj.text;
        },
    },
};
</script>

<style lang="stylus">
.detail-block {
    display: grid;

    &__icon {
        fill: $nebula-color-platform-interface-800;
        margin-right: $nebula-space-1x;
    }

    &__icon-label {
        align-items: center;
        display: flex;
        margin-bottom: $nebula-space-1x;
        padding-right: $nebula-space-6x;
    }

    &__label {
        body-1();
    }
}

@container (max-width: 767px) {
    .detail-block {
        grid-template-columns: repeat(2, auto);
        &--vertical {
            grid-template-columns: repeat(1, auto);
        }
    }
}

@container (min-width: 768px) {
    .detail-block {
        grid-template-columns: repeat(2, auto);
        &--vertical {
            max-width: 700px;
        }
    }
}
@container (min-width: 1200px) {
    .detail-block {
        grid-template-columns: repeat(3, auto);
        max-width: 700px;
    }
}

@container (max-width: 400px) {
    .detail-block--simple {
        grid-template-columns: repeat(1, auto);
    }
}
</style>
