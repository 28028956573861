import i18next from 'i18next';

import getOrdinalSuffix from './getOrdinalSuffix';

const gradeDisplayMap = {
    k: i18next.t('kindergarten', { ns: 'requests' }),
};

function getGradeDisplay(grade) {
    if (!grade) {
        return null;
    }
    if (Number.isNaN(parseInt(grade, 10))) {
        console.log('not a number detected');
        console.log(i18next.t);
        return gradeDisplayMap[grade.toLowerCase()];
    }

    return `${getOrdinalSuffix(grade)} Grade`;
}

export default getGradeDisplay;
