<template>
    <div class="educator-upcoming">
        <div class="educator-upcoming__section-title">
            <slot name="sectionTitle" />
        </div>
        <div class="educator-upcoming__content-wrapper">
            <div class="educator-upcoming__content">
                <div class="educator-upcoming__title">
                    <slot name="title" />
                </div>
                <div class="educator-upcoming__summary">
                    <div class="educator-upcoming__image">
                        <slot name="image" />
                    </div>

                    <div class="educator-upcoming__details">
                        <slot name="details" />
                    </div>
                </div>
            </div>
            <div class="educator-upcoming__actions">
                <slot name="joinLink" />
                <div class="educator-upcoming__participant">
                    <slot name="participant" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UpcomingRequestEducator',
};
</script>

<style lang="stylus">
.educator-upcoming {
    width: 100%;
    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        display: flex;
        flex-direction: column;
    }
    &__section-title {
        flex-basis: 100%;
    }
    &__content {
        flex: 2;
        display: flex;
        flex-direction: column;
    }
    &__content-wrapper {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
        }
    }
    &__actions {
        flex: 1;
    }
    &__summary {
        margin-block-start: $nebula-space-3x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
        }
    }
    &__details {
        min-width: 300px;
        max-width: 500px;
        container-type: inline-size;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    &__image {
        display: none;
        margin-inline-end: $nebula-space-5x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: block;
        }
    }
    &__title {
        margin-block-start: $nebula-space-2x;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            margin-block-end: $nebula-space-2x;
        }
    }
    &__participant {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            margin-block-start: $nebula-space-3x;
        }
    }
}
</style>
