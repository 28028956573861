<template>
    <div class="connector-upcoming">
        <div class="connector-upcoming__content">
            <div class="connector-upcoming__section-title">
                <slot name="sectionTitle" />
            </div>
            <div class="connector-upcoming__image">
                <slot name="image" />
            </div>
            <div class="connector-upcoming__summary">
                <div class="connector-upcoming__title">
                    <slot name="title" />
                </div>
                <slot name="details" />
            </div>
        </div>
        <div class="connector-upcoming__actions">
            <slot name="joinLink" />
            <slot name="participant" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UpcomingRequestConnector',
};
</script>

<style lang="stylus">
.connector-upcoming {
    width: 100%;
    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        display: flex;
    }
    &__section-title {
        flex-basis: 100%;
    }
    &__content {
        display: flex;
        flex: 2;
        flex-wrap: wrap;
    }
    &__actions {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
    &__summary {
        margin-block-start: $nebula-space-3x;
    }
    &__image {
        display: none;
        margin-inline-end: $nebula-space-5x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: block;
        }
    }
    &__title {
        margin-block-end: $nebula-space-2x;
    }
}
</style>
